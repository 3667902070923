<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">

          <el-form-item label="日期">
            <el-date-picker v-model="search.time" type="date" class="w200" placeholder="选择日期"
              value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>

          <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
            <el-select v-model="search.shop_id" clearable remote filterable :remote-method="remoteMethod"
              placeholder="请输入" class="w120">
              <el-option v-for="item in options.shop_id" :key="item.shopId" :label="item.shopName"
                :value="item.shopId" />
            </el-select>
          </el-form-item>

          <el-form-item label="提示">
            <i class="el-icon-warning" style="color: #EF9187;background-color: #EF9187;"></i>已核销&nbsp;
            <i class="el-icon-warning" style="color: #F7D47F;background-color: #F7D47F;"></i>未核销&nbsp;
            <i class="el-icon-warning" style="color: #FFFFFF;background-color: #FFFFFF;border: solid 1px black;"></i>未预约
          </el-form-item>

        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <!-- <el-button
          v-if="!userInfo.shop_id"
          type="primary"
          :disabled="!idList.length"
          class="mb10"
          size="mini"
          @click="hint('导出选中列表')"
        >导出数据</el-button> -->
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table :data="list" size="small" border :cell-style="cellStyle" :header-cell-style="$style.rowClass">
            <ElTableColumn label="员工" prop="person_name" />
            <el-table-column v-for="(item,index) in times" :label="item" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.distribution[index].status == 0" class="hand"
                  @click="tapBtn(scope.row.shop_person_id,scope.row.distribution[index],scope.row.person_name)">
                  {{scope.row.distribution[index].customer}} &nbsp;</div>
                <div v-if="scope.row.distribution[index].status == 1" class="hand"
                  @click="tapBtn(scope.row.shop_person_id,scope.row.distribution[index],scope.row.person_name)">
                  {{scope.row.distribution[index].customer}} &nbsp;</div>
                <div v-if="scope.row.distribution[index].status == 2" class="hand"
                  @click="tapBtn(scope.row.shop_person_id,scope.row.distribution[index],scope.row.person_name)">
                  {{scope.row.distribution[index].customer}} &nbsp;</div>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog :title="info.person_name" :visible.sync="dialogVisible" width="30%">
            <el-form class="search" label-suffix="：" :inline="true" size="mini" :model="info">
              <el-form-item label="客户">
                <el-select v-model="info.customer_id" clearable remote filterable placeholder="请输入" class="w120">
                  <el-option v-for="item in customerlist" :key="item.id" :label="item.customer_name" :value="item.id" />
                </el-select>
              </el-form-item>
              <el-form-item label="时间">
                <el-time-select placeholder="起始时间" v-model="info.start_time" :picker-options="{
                    start: '09:00',
                    step: '00:30',
                    end: '20:00'
                  }">
                </el-time-select>
                <el-time-select placeholder="结束时间" v-model="info.end_time" :picker-options="{
                    start: '09:00',
                    step: '00:30',
                    end: '20:00',
                    minTime: info.start_time
                  }">
                </el-time-select>
              </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="add()">确 定</el-button>
            </span>
          </el-dialog>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { newbooksList,personcustomerList,submitBooks,getBookId,cancelBook } from "@/api/client";
import { getShopList } from "@/api/shop";

class Search {
  time=""; //预约时间
  shop_id="73782774834649"; // 归属门店
}
class Info{
  person_name="";
  person_id=0;
  customer_id="";
  start_time="";
  end_time="";
  date = "";
}
class Options {
  shop_id = [
    {'shopId':73782774834649,'shopName':'大学路'}
  ];
}
class Book{
    book_id="";
}
export default {
  name: "StaffList",
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(),
      info:new Info(),
      book:new Book(),
      customerlist:[],
      list: [], // 数据展示
      times:[],
      dialogVisible:false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    if (this.userInfo.shop_id) {
      this.search.shop_id = this.userInfo.shop_id;
    } 
    else {
      this.remoteMethod('73782774834649') 
      this.search.shop_id = '73782774834649';
      
    }
    
    this.getList();
  },
  methods: {
    // 获取列表
    getList(option) {
        newbooksList({
            ...this.search,
        }).then(res => {
            this.list = res.data.data;
            this.times = res.data.times;
        });
    },
    tapBtn(person_id,distribution,person_name)
    {
      this.info = new Info();
      this.info.person_id=person_id;
      this.info.person_name=person_name;
      this.info.date = this.search.time;
      this.info.start_time = distribution.time;
      this.customerlist = [];
      if(distribution.status==0)
      {
        getBookId({
          ...this.info
        }).then(res => {
             this.book.book_id=res.data.id;
        });
        let str = "此操作将取消【"+distribution.customer+"】预约护理师【"+person_name+"】的预约记录";
        this.$confirm(str, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          cancelBook({
              ...this.book,
          }).then(res => {
              if(res.code==200)
              {
                this.$message({
                  type: 'success',
                  message: '取消预约成功!'
                });
              }else{
                this.$message({
                  type: 'warning',
                  message: '取消预约失败'
                });
              }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
      }
      if(distribution.status==1)
      {
                this.$message({
                  type: 'warning',
                  message: '已核销'
                });
      }
      if(distribution.status==2)
      {
        this.dialogVisible=true;
        personcustomerList({
          person_id:person_id
        }).then(res => {
            this.customerlist = res.data;
            this.getList();
        });

      }
    },
    add()
    {
      this.dialogVisible=false;
      submitBooks({
        ...this.info
      }).then(res => {
            if(res.code == 200)
            {
              this.$message({
                type: "success",
                message: res.msg
              });
             this.getList();
            }else{
              this.$message({
                type: "warning",
                message: res.msg
              });
            }
        });

    },
    cellStyle({row, column, rowIndex, columnIndex}){
        if(columnIndex <= 0){
            return 'background:#E7FEB5;text-align:center'
        }
        // console.log(row.distribution[columnIndex-1].status);
        if(row.distribution[columnIndex-1].status==1)
        {
          return 'background:#EF9187;text-align:center'
        }
        if(row.distribution[columnIndex-1].status==0)
        {
          return 'background:#F7D47F;text-align:center'
        }

        return '';
      },

    // 搜索门店
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },

  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 5px 40px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
</style>
